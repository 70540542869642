// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  box-sizing: border-box;
  margin: 0%;
  padding: 0%;

}

ul {
  list-style-type: none;
}

a {
  color: #423E3A ;
  text-decoration: none;
}

body {
  font-size: 1.2rem;
  line-height: 1.3;

}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,UAAU;EACV,WAAW;;AAEb;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,eAAe;EACf,qBAAqB;AACvB;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;;AAElB","sourcesContent":["* {\n  box-sizing: border-box;\n  margin: 0%;\n  padding: 0%;\n\n}\n\nul {\n  list-style-type: none;\n}\n\na {\n  color: #423E3A ;\n  text-decoration: none;\n}\n\nbody {\n  font-size: 1.2rem;\n  line-height: 1.3;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
