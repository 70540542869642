// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Footer_footerContainer__cqEAW {
    display: flex;
    position:static;
    bottom: 0%;
    height: 100px;
    width: 100vw;
    background-color: rgb(242, 229, 220);
}

.Footer_linkContainer__VKf8M {
    padding: 35px;
}

.Footer_links__wMgLO {
    padding-left: 10px;
    height: 50px;
    width: 50px;
    color: black;
}`, "",{"version":3,"sources":["webpack://./src/components/Footer/Footer.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe;IACf,UAAU;IACV,aAAa;IACb,YAAY;IACZ,oCAAoC;AACxC;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,WAAW;IACX,YAAY;AAChB","sourcesContent":[".footerContainer {\n    display: flex;\n    position:static;\n    bottom: 0%;\n    height: 100px;\n    width: 100vw;\n    background-color: rgb(242, 229, 220);\n}\n\n.linkContainer {\n    padding: 35px;\n}\n\n.links {\n    padding-left: 10px;\n    height: 50px;\n    width: 50px;\n    color: black;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footerContainer": `Footer_footerContainer__cqEAW`,
	"linkContainer": `Footer_linkContainer__VKf8M`,
	"links": `Footer_links__wMgLO`
};
export default ___CSS_LOADER_EXPORT___;
