// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/MaisonDeArtisanFree-Regular.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: Qualey;
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___})
  }

.Work_container__992xG { 
    display: inline-block;
    justify-content: center;
    overflow-wrap: break-word;
    overflow: auto;
    justify-content: center;
    margin: 30px;
}

.Work_portfolio__2FWtK {
    text-align: center;
    font-size: 20px;
    padding-top: 50px;
    font-family:  Qualey;
}

.Work_container__992xG img {
   max-width: 100%;
   height: 450px;
   width: 310px;
   justify-content: center;
   padding: 25px;
   cursor: pointer;
}

.Work_container__992xG img:hover {
  opacity: .5 ;
}


@media screen and (max-width: 720px) {
    .Work_container__992xG { 
        display: inline;
        justify-content: center;
    }
    
    .Work_portfolio__2FWtK {
        text-align: center;
        font-size: 15px;
    }
    
    .Work_container__992xG img {
     height: auto;
       justify-content: center;
      
    }
}

`, "",{"version":3,"sources":["webpack://./src/components/Work/Work.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB;EACF;;AAEF;IACI,qBAAqB;IACrB,uBAAuB;IACvB,yBAAyB;IACzB,cAAc;IACd,uBAAuB;IACvB,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,eAAe;IACf,iBAAiB;IACjB,oBAAoB;AACxB;;AAEA;GACG,eAAe;GACf,aAAa;GACb,YAAY;GACZ,uBAAuB;GACvB,aAAa;GACb,eAAe;AAClB;;AAEA;EACE,YAAY;AACd;;;AAGA;IACI;QACI,eAAe;QACf,uBAAuB;IAC3B;;IAEA;QACI,kBAAkB;QAClB,eAAe;IACnB;;IAEA;KACC,YAAY;OACV,uBAAuB;;IAE1B;AACJ","sourcesContent":["@font-face {\n    font-family: Qualey;\n    src: url(../../assets/MaisonDeArtisanFree-Regular.otf)\n  }\n\n.container { \n    display: inline-block;\n    justify-content: center;\n    overflow-wrap: break-word;\n    overflow: auto;\n    justify-content: center;\n    margin: 30px;\n}\n\n.portfolio {\n    text-align: center;\n    font-size: 20px;\n    padding-top: 50px;\n    font-family:  Qualey;\n}\n\n.container img {\n   max-width: 100%;\n   height: 450px;\n   width: 310px;\n   justify-content: center;\n   padding: 25px;\n   cursor: pointer;\n}\n\n.container img:hover {\n  opacity: .5 ;\n}\n\n\n@media screen and (max-width: 720px) {\n    .container { \n        display: inline;\n        justify-content: center;\n    }\n    \n    .portfolio {\n        text-align: center;\n        font-size: 15px;\n    }\n    \n    .container img {\n     height: auto;\n       justify-content: center;\n      \n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Work_container__992xG`,
	"portfolio": `Work_portfolio__2FWtK`
};
export default ___CSS_LOADER_EXPORT___;
