// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.Video_video__Lxu7l {
    height: 600px;
    width: 100%;
    text-decoration: none;
    padding-top: 20px;
}

@media screen and (max-width: 720px) {
    .Video_video__Lxu7l {
       height: 500px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/Video/Video.module.css"],"names":[],"mappings":";;AAEA;IACI,aAAa;IACb,WAAW;IACX,qBAAqB;IACrB,iBAAiB;AACrB;;AAEA;IACI;OACG,aAAa;IAChB;AACJ","sourcesContent":["\n\n.video {\n    height: 600px;\n    width: 100%;\n    text-decoration: none;\n    padding-top: 20px;\n}\n\n@media screen and (max-width: 720px) {\n    .video {\n       height: 500px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"video": `Video_video__Lxu7l`
};
export default ___CSS_LOADER_EXPORT___;
