// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Contact_email__qed\\+o {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px ;
}`, "",{"version":3,"sources":["webpack://./src/components/Contact/Contact.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;AACjB","sourcesContent":[".email {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    margin: 30px ;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"email": `Contact_email__qed+o`
};
export default ___CSS_LOADER_EXPORT___;
