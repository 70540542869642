// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/MaisonDeArtisanFree-Regular.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family:Restora;
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___})
  }

.About_container__2HlvB { 
    display: flex;
    justify-content:space-evenly;
    align-items: center;
    padding: 10px;
}

.About_container__2HlvB img {
    max-width: 100%;
    height: 600px;
    width: 450px;
    justify-content: center;
    padding: 25px;
    
 }
 
 .About_words__FAOje {
    font-family: Restora;
    padding-left: 40px;
    padding-right: 40px;
 }

@media screen and (max-width: 720px) {
    .About_container__2HlvB { 
        display: inline;
        justify-content: center;
    }
    
    .About_portfolio__UR8b2 {
        text-align: center;
        font-size: 15px;
    }
    
    .About_container__2HlvB img {
     height: auto;
       justify-content: center;
      
    }

    .About_words__FAOje {
        font-family: Restora;
        padding: 20px;
        text-align: center;
     }
}
`, "",{"version":3,"sources":["webpack://./src/components/About /About.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB;EACF;;AAEF;IACI,aAAa;IACb,4BAA4B;IAC5B,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI,eAAe;IACf,aAAa;IACb,YAAY;IACZ,uBAAuB;IACvB,aAAa;;CAEhB;;CAEA;IACG,oBAAoB;IACpB,kBAAkB;IAClB,mBAAmB;CACtB;;AAED;IACI;QACI,eAAe;QACf,uBAAuB;IAC3B;;IAEA;QACI,kBAAkB;QAClB,eAAe;IACnB;;IAEA;KACC,YAAY;OACV,uBAAuB;;IAE1B;;IAEA;QACI,oBAAoB;QACpB,aAAa;QACb,kBAAkB;KACrB;AACL","sourcesContent":["@font-face {\n    font-family:Restora;\n    src: url(../../assets/MaisonDeArtisanFree-Regular.otf)\n  }\n\n.container { \n    display: flex;\n    justify-content:space-evenly;\n    align-items: center;\n    padding: 10px;\n}\n\n.container img {\n    max-width: 100%;\n    height: 600px;\n    width: 450px;\n    justify-content: center;\n    padding: 25px;\n    \n }\n \n .words {\n    font-family: Restora;\n    padding-left: 40px;\n    padding-right: 40px;\n }\n\n@media screen and (max-width: 720px) {\n    .container { \n        display: inline;\n        justify-content: center;\n    }\n    \n    .portfolio {\n        text-align: center;\n        font-size: 15px;\n    }\n    \n    .container img {\n     height: auto;\n       justify-content: center;\n      \n    }\n\n    .words {\n        font-family: Restora;\n        padding: 20px;\n        text-align: center;\n     }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `About_container__2HlvB`,
	"words": `About_words__FAOje`,
	"portfolio": `About_portfolio__UR8b2`
};
export default ___CSS_LOADER_EXPORT___;
